import NonPersistentStorage from './NonPersistentStorage';
import PersistentStorage from './PersistentStorage';

const Config = new NonPersistentStorage();
/* global window */
const Navigation = new PersistentStorage('navigation', window.sessionStorage);

const Basket = new PersistentStorage('basket', window.sessionStorage);
if(!Basket.storeWasRegenerated()){
	Basket.set('lastSubsidiary', null);
	Basket.set('products', {});
}


export {Config, Navigation, Basket};