<template>
	<div>
		<div>
			<h3>Bestellung erfogreich</h3>
		</div>
		<div>
			Bestellnummer: {{data.ident}}
		</div>
		<div v-if="domUrl">
			<embed class="billPdf" :src="domUrl" type="application/pdf">
		</div>
		<button type="button" @click="back">zurück zur Übersicht</button>
	</div>
</template>

<script>
import {Basket, Navigation} from '../storage';
import emitter from '../Emitter';

export default {
	name: "OrderSuccess",
	data(){
		return {
			domUrl: null,
		};
	},
	props:{
		data: {
			type: Object,
		}
	},
	mounted(){
		this.createPdf();
		
		this.resetBasket();
		
		Navigation.clear();
		
		
	},
	destroyed(){
		window.URL.revokeObjectURL(this.domUrl);
	},
	methods:{
		createPdf(){
			const binary = atob(this.data.bill);
			const len = binary.length;
			const buffer = new ArrayBuffer(len);
			let view = new Uint8Array(buffer);
			for(let i = 0; i < len; i++) {
				view[i] = binary.charCodeAt(i);
			}
			const file = new Blob([view], {
				type: 'application/pdf',
				name: 'rechnung.pdf',
			});
			
			this.domUrl = window.URL.createObjectURL(file);
		},
		resetBasket(){
			Basket.set('basket', {});
		},
		back(){
			emitter.emit('navigationChange', {
				component: 'SubsidiarySelector',
				properties: {},
			});
		},
	},
}
</script>

<style scoped lang="scss">
	.billPdf{
		width: 100%;
		min-height: 50vh;
	}
</style>