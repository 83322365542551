<template>
	<div class="headerBannerWrapper" v-if="bannerImage">
		<img class="header-image" :src="bannerImage" />
    <div class="subsidiaryLocationWrapper">
      <div class="logo">
        <img class="logo-image" :src="logoImage" />
      </div>
      <transition name="slide-in">
        <div v-if="mainComponent && mainComponent != 'SubsidiarySelector'" class="city bolded">{{ selectedSubsidiary.location.city }}</div>
      </transition>
    </div>
    <BurgerMenu :mobile="mobile"/>
	</div>
</template>

<script>
import {Basket, Config} from '../storage';
import emitter from '../Emitter';
import {Navigation} from '../storage';
import BurgerMenu from "./BurgerMenu";

export default {
	name: "Header",
  components: {
    BurgerMenu
  },
	data(){
		return {
			bannerImage: null,
      logoImage: null,
      selectedSubsidiary: null,
      mainComponent: '',
      showBurgerMenu: false
		}
	},
  props: {
    mobile: {
      type: Boolean,
    }
  },
	created(){
		this.bannerImage = Config.get('headerImage', null);
    this.logoImage = Config.get('logoImage',null);

    this.mainComponent = Navigation.store.mainComponent;
    this.selectedSubsidiary = Basket.get('lastSubsidiary');
    emitter.on('navigationChange', this.updateCity);
	},
  methods: {
    updateCity (props) {
      if (props.properties.subsidiary) {
        this.selectedSubsidiary = props.properties.subsidiary;
      } else {
        const subsidiary = Basket.get('lastSubsidiary');
        this.selectedSubsidiary = subsidiary;
      }
      this.mainComponent = Navigation.store.mainComponent;
    },
    toggleBurgerMenu () {
      this.showBurgerMenu = !this.showBurgerMenu;
    }
  }
}
</script>

<style scoped lang="scss">
	.headerBannerWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    .header-image{
      min-height: 200px;
      max-height: 400px;
      min-width: 100%;
      object-fit: cover;
    }
    .subsidiaryLocationWrapper {
      position: absolute;
      top: 0;
      left: 16px;
      width: calc(100% - 16px);
      height: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      overflow: hidden;
      .logo {
        width: 25%;
        @include md {
          width: 15%;
        }
        @include xl {
          width: 10%;
        }
        max-height: 75%;
        z-index: 1;
        img {
          width: 100%;

        }
      }
      .city {
        display: flex;
        background: $bgColor;
        margin-left: -25px;
        padding: 5px 25px;
        border-radius: 0 10px 10px 0;
        z-index: 0;
      }

      .slide-in-enter-active, .fade-leave-active {
        transition: all 0.2s;
      }
      .slide-in-enter-from, .fade-leave-to {
        transform: translateX(calc(-100%));
      }
    }
  }
</style>