<template>
  <div class="modalHeader">
    <h3 class="bolded">
      {{ headline }}
    </h3>
  </div>

  <div class="modalBody">
    <p v-html="message"></p>
    <transition name="fade">
      <div class="notificationWrapper" v-if="showNotification" v-html="notificationMessage"></div>
    </transition>
    <div class="dateSelectionWrapper" v-if="showDatePicker === false">
      <button type="button" @click="sendDate">Morgen</button>
      <button type="button" @click="showDatePicker = true">Termin</button>
    </div>
    <div class="datePickerWrapper" v-if="showDatePicker">
      <input class="orderDatePicker" type="date" placeholder="Abholdatum" :min="minDate" :max="maxDate" v-if="showDatePicker === true" @change="checkDate" >
      <button id="Holma_Test" type="button" @click="checkDate">
        <span class="material-icons">
          east
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import {Basket} from "../storage";

export default {
  name: "OrderDateSelection",
  components: {

  },
  data(){
    return {
      showNotification: false,
      notificationMessage: '',
      orderDate: '',
      showDatePicker: false,
      minDate: '',
      maxDate: ''
    };
  },
  props: {
    headline: {
      type: String
    },
    message: {
      type: String
    },
    subsidiary: {
      type: Object
    },
    subsidiaryIndex: {
      type: String
    }
  },
  async created(){
    let date = new Date();
    date.setDate(date.getDate()+2);
    this.minDate = date.toISOString().substr(0,10);
    date.setMonth(date.getMonth()+3);
    this.maxDate = date.toISOString().substr(0,10);
  },
  methods:{
    checkDate (event) {
      let element = event.target.closest('.datePickerWrapper').children[0];
      let verifiedDate = false;
      element.className = (element.value != '' ? 'has-value' : '');
      if (!element.validity.valid) {
        this.notificationMessage = element.validationMessage;
        this.showNotification = true;
      } else {
        let openingHours = this.subsidiary.opening_hours;
        let date = new Date(element.value);
        let i;
        for (i = 0; i < openingHours.length; i++) {
          if (date.getDay() == openingHours[i].weekday) {
            verifiedDate = true;
            this.orderDate = element.value;
          }
        }
      }

      if (verifiedDate) {
        this.notificationMessage = '';
        this.showNotification = false;

        Basket.set('orderDate', this.orderDate);
        if (event.target.localName != 'input') {
          this.$emit('dateSelected', this.subsidiaryIndex);
        }
      } else {
        this.notificationMessage = 'Das gewählte Datum ist leider ungültig.';
        this.showNotification = true;
        return;
      }
    },
    sendDate () {
      let date = new Date();
      date.setDate(date.getDate()+1);
      Basket.set('orderDate', date.toISOString().substr(0, 10));

      this.$emit('dateSelected', this.subsidiaryIndex);
    }
  }
}
</script>

<style scoped lang="scss">
  .modalHeader {
    h3 {
      margin-top: 0;
      color: $mainColor;
    }
  }

  .modalBody {
    margin: 20px 0;
    .notificationWrapper {
      margin: 5px 0;
      padding: 5px;
      border: 1px solid red;
      border-radius: 5px;
      background: rgba(255,0,0,0.3);
    }
    .dateSelectionWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 95%;
      button {
        margin: 10px;
      }
      span {
        margin: 10px 0;
        padding: 4px;
      }
    }
    .datePickerWrapper {
      display: grid;
      grid-template-columns: 85% 15%;
      @include md {
        max-width: 400px;
        margin: 0 auto;
      }
      input {
        width: 100%;
        margin: 10px 0 10px 0;
        padding: 3px 10px;
        border-radius: 5px 0 0 5px;
        background: $mainColor;
        text-align: center;
        &[type="date"] {
          &:not(.has-value) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &::-webkit-datetime-edit {
              display: none;
            }
            &::-webkit-calendar-picker-indicator {

            }
            &:before {
              color: $lightFont;
              content: attr(placeholder);
              width: 100%;
            }
          }
        }
      }
      input[type="date"]:not(.has-value):before{

      }
      button {
        margin: 10px 0 10px 0;
        border-radius: 0 5px 5px 0;
        border: solid $mainColor 1px;
        background: transparent;
        padding: 3px 10px;
      }
    }

    .fade-enter-active, .fade-leave-active {
      transition: all 0.5s;
    }
    .fade-enter-from, .fade-leave-to /* .list-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }
</style>