<template>
	<div class="subsidiary-card" tabindex="0" aria-label="Filialauswahl">
		<div class="subsidiary-image-border">
			<img v-if="false" :src="imageHref" />
			<div v-else class="subsidiary-image-fallback">
        <div>Filliale</div>
				<h3>{{ subsidiary.location.city }}</h3>
        <div>&gt;&gt;&gt; Hier klicken &lt;&lt;&lt;</div>
			</div>
		</div>
		<div class="subsidiary-adress-border">
			<!--adresse -->
			<div>
        <p class="city bolded">{{ subsidiary.location.zip }} {{subsidiary.location.city}}</p>
        <p>{{subsidiary.location.street}} {{subsidiary.location.street_nr}}</p>
      </div>
			<p v-if="subsidiary.phone"><a :href="phoneHref">Tel: {{ subsidiary.phone }}</a></p>
		</div>
	</div>
</template>

<script>
import {Config} from '../storage';

export default {
	name: "SubsidiaryCard",
  components: {

  },
	data(){
		return {};
	},
	props: {
		subsidiary: {
			type: Object,
			default: () => {return {}},
		},
	},
	computed: {
		phoneHref: function(){
			return `tel:${this.subsidiary.phone.replace(/[^0-9]/g, '')}`;
		},
    imageHref: function () {
      return Config.get('apiUrl') + `/${this.subsidiary.image}`;
    }
	}
}
</script>

<style scoped lang="scss">
	.subsidiary-card{
		background-color: $bgColor;
		width: 100%;
		display:flex;
		flex-direction: column;
		margin-bottom: 32px;
		cursor: pointer;
		
		.subsidiary-image-border{
			width: 100%;
      height: 175px;
      overflow: hidden;
			img{
				width: 100%;
        transform: scale(1);
        transition: all 0.3s;
        &:hover {
          transform: scale(1.1);
        }
			}
		}
		
		.subsidiary-image-fallback{
			display:flex;
			flex-direction: column;
			justify-content: center;
			background-color: $bgDark;
			height: 175px;
			text-align: center;

      div {
        color: $lightFont;
        font-size: 28px;
        font-weight: lighter;

        &:last-child {
          font-size: 20px;
        }
      }
			h3{
        color: $lightFont;
				font-size: 40px;
        font-weight: bold;
			}
		}
		
		.subsidiary-adress-border{
			display:flex;
			flex-direction: column;
			text-align: center;
			justify-content: space-evenly;
			min-height: 120px;
      @include md {
        min-height: 200px;
      }
			box-shadow: 0px 6px 4px -6px $shadowColor;

      >div>p {
        display: block;
      }
      .bolded {
        font-size: 18px;
      }
		}
	}
</style>