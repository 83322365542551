import {Config} from './storage';

const HTTP_METHODS = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
};


const restClient = {
	async ajax(params){
		const baseUrl = Config.get('apiUrl') + '/sys/tools/rest';
		const token = Config.get('apiToken');
		let url = `${baseUrl}/${params.endpoint}`;
		
		const headers = {
			'Content-Type': 'application/json',
			'AuthKind': 'auth_token',
			'Authorization': `Bearer ${token}`,
		};
		
		if(!params.method){
			params.method = HTTP_METHODS.GET;
		}
		
		if(!HTTP_METHODS[params.method]){
			throw new Error(`Unknown HTTP Method "${params.method}"`);
		}
		
		let body = null;
		if(params.data){
			switch(params.method){
				case HTTP_METHODS.GET:
					url += '?' + Object.entries(params.data).map(([key, value,]) => {
						return `${key}=${typeof value === 'object'? encodeURIComponent(JSON.stringify(value)) : value}`
					}).join('&');
					break;
				
				default:
					if(params.data instanceof FormData){
						headers['Content-Type'] = 'multipart/form-data;charset=utf-8;';
						body = params.data;
					}else{
						body = JSON.stringify(params.data);
					}
			}
		}
		
		const request = {
			method: params.method,
			redirect: 'follow',
			mode: 'cors',
			headers: headers,
		}
		
		if(body){
			request.body = body;
		}
		document.dispatchEvent(new Event('ajaxStarted'));
		let response = {};
		try{
			response = await fetch(url, request);
		}catch(e){
			document.dispatchEvent(new Event('ajaxEnded'));
			throw e;
		}
		document.dispatchEvent(new Event('ajaxEnded'));
		
		if(response.status >= 500 && response.status < 600){
			throw await response.text();
		}
		if(response.status < 200 || response.status >= 300){
			let res;
			try{
				res = await response.json();
			}catch(e){
				res = null;
			}
			return res;
		}
		
		if(response.status !== 204){
			if(params.download){
				return response.blob();
			}else{
				return response.json();
			}
		}else{
			return true;
		}
	},
}

export default restClient;