<template>
	<div id="paypal_container" class="paypal_container">
	</div>
</template>

<script>
import {Basket} from '../storage';
import {FloatFormatter} from '../util';
import restClient from '../rest';
import emitter from '../Emitter';

export default {
	name: "paypalButtons",
	components: {},
	data(){
		return {
			actions: null,
		};
	},
	
	props: {
		totalVat: {
			type: Number,
		},
		totalSum: {
			type: Number,
		},
		products: {
			type: Object,
		},
		orderName: {
			type: String,
		},
		orderDate: {
			type: String,
		},
		orderTime: {
			type: String,
		},
    orderMemo: {
      type: String
    },
		isActive: {
			type: Boolean,
		},
	},
	watch: {
		isActive(newValue, oldValue){
			console.log('watcher', oldValue, newValue);
			if(newValue){
				this.actions.enable();
			}else{
				this.actions.disable();
			}
			
		},
	},
	async created(){
		const items = []
		let current_quant = 0;
		const target_copy = JSON.parse(JSON.stringify(this.products))
		
		for(let basketkey in Basket.get('basket')){
			current_quant = Basket.get('basket')[basketkey];
			items.push({
				price: this.products[basketkey].price,
				sku: basketkey,
				quantity: current_quant,
				vat: this.products[basketkey].vat,
				currency: 'EUR',
				name: this.products[basketkey].name,
			})
		}
		this.$nextTick(() => {
			let that = this;
			var paypal_id;
			paypal.Buttons({
				onInit(data, actions){
					if(!that.isActive){
						actions.disable();
					}
					that.actions = actions;
				},
				createOrder: function(data, actions){
					return actions.order.create({
						"intent": "CAPTURE",
						purchase_units: [{
							amount: {
								"details": {
									"tax": FloatFormatter.format(that.totalVat),
								},
								value: FloatFormatter.format(parseFloat(that.totalSum) + parseFloat(that.totalVat)),
								"currency": "EUR",
								items: items,
							},
						}]
					});
				},
				async onApprove(data, actions){
					return actions.order.capture().then(async function(details){
						const result = await restClient.ajax({
							method: 'POST',
							endpoint: 'Holma/createReceipt',
							data: {
								token: data.orderID,
								payerID: data.payerID,
								details: details,
								data: data,
								orderName: that.orderName,
								orderDate: that.orderDate,
								orderTime: that.orderTime,
                orderMemo: that.orderMemo,
								items: items,
								subsidiary: Basket.get('lastSubsidiary').id,
							}
						});
						
						if(result.success){
							emitter.emit('navigationChange', {
								component: 'OrderSuccess',
								componentDisplayName: 'Bestellung erfolgreich',
								properties:{
									data: result.data,
								}
							});
						}else{
							emitter.emit('navigationChange', {
								component: 'OrderError',
								componentDisplayName: 'Bestellung fehlgeschlagen',
								properties:{
									error: result.error,
								}
							});
						}
					});
				},
			}).render('#paypal_container');
		});
	},
	methods: {
		create_booking(order){
		},
	},
};

</script>

<style>
.paypal_container * {
	font-size: 0px !important;
}

.paypal_container {
	margin: auto;
	display: flex !important;
	margin-top: 40px;
	justify-content: center;
}

</style>