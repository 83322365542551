<template>
	<div class="errorMessageWrapper">
    <h2 class="bolded">Sorry</h2>
    <div v-if="error['stock_missmatch']">
      Offenbar gab es bei der gewählten Bestellmenge Unstimmigkeiten.
    </div>
    <div v-if="error.price_missmatch">
      Die übermittelten Preise stimmen nicht mit den gewählten Artikeln überein.
    </div>
    <div v-if="error.total_sum_missmatch">
      Der zu zahlende Betrag stimmt nicht mit dem errechneten Gesamtbetrag überein.
    </div>
    <div v-if="error.invalid_date">
      Es wurde ein ungültiges Datum für die Abholung angegeben.
      <div v-if="error.invalid_date_message" v-html="error.invalid_date_message"></div>
    </div>
    <div v-if="error.invalid_time">
      Die für die Abholung gewählte Uhrzeit liegt offenbar außerhalb der Öffnungszeiten.
    </div>
		<div>
			Bitte versuchen sie es in Kürze nocheinmal.
		</div>
		<div>
			<button type="button" @click="back">zurück zur Übersicht</button>
		</div>
	</div>
</template>

<script>
import emitter from '../Emitter';

export default {
	name: "OrderError",
  props: {
    error: {
      type: Object,
      default: {
        'invalid': false,
        'stock_missmatch': false,
        'price_missmatch': false,
        'total_sum_missmatch': false,
        'invalid_date': false,
        'invalid_time': false
      }
    }
  },
	methods:{
		back(){
			emitter.emit('navigationChange', {
				component: 'SubsidiarySelector',
				properties: {},
			});
		},
	},
}
</script>

<style scoped lang="scss">
  .errorMessageWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    h2 {
      font-size: 40px;
      margin: 16px 0;
    }
    > div {
      margin: 8px 0;
    }
  }
</style>