<template>
	<div class="back-button">
		<span
			class="clickable"
			@click="onClick()"
			role="button"
			aria-label="zurück"
		>
			<span class="material-icons">
				arrow_back
			</span>
			<span>
				zurück zur {{ componentName }}
			</span>
		</span>
	</div>
</template>

<script>
import {Navigation} from '../storage';
import emitter from '../Emitter';

export default {
	name: "BackButton",
	data(){
		return {

		}
	},
	props: {
		component: {
			type: String,
		},
		componentName: {
			type: String,
		},
	},
	created(){
	},
	methods: {
		onClick(){
			emitter.emit('navigationChange', {
				component: this.component,
				componentDisplayName: this.componentName,
				properties: Navigation.get('lastComponentParams', {}),
			});
		},
	}
}
</script>

<style scoped lang="scss">
	.back-button{
		width: 100%;
		background-color: $bgDark;
		color: $lightFont;
		margin: 4px 0;
    padding-left: 15px;
		span{
			padding: 4px 0 4px 0;
      vertical-align: middle;
		}
	}
</style>