class NonPersistentStorage{
	
	constructor(){
		this.store = {};
	}
	
	/**
	 * @param {String} key
	 * @param {*} value
	 * @return {NonPersistentStorage}
	 */
	set(key, value){
		this.store[key] = value;
		return this;
	}
	
	/**
	 * @param {String} key
	 * @param {*} defaultValue
	 * @return {*}
	 */
	get(key, defaultValue = undefined){
		if(this.has(key)){
			return this.store[key];
		}else{
			return defaultValue;
		}
	}
	
	/**
	 * @param {String} key
	 * @return {Boolean}
	 */
	has(key){
		return typeof this.store[key] !== 'undefined';
	}
	
	/**
	 * @return {Object}
	 */
	list(){
		return this.store;
	}
	
	/**
	 * @return {NonPersistentStorage}
	 */
	clear(){
		this.store = {};
		return this;
	}
	
	/**
	 * @param {string} key
	 * @return {NonPersistentStorage}
	 */
	delete(key){
		if(this.has(key)){
			delete this.store[key];
		}
		return this;
	}
	
	/**
	 * @return {string[]}
	 */
	keys(){
		return Object.keys(this.store);
	}
}

export default NonPersistentStorage;