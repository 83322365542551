<template>
  <transition name="modal">
    <div v-if="showModal" class="modalMask">
      <div class="modalWrapper" @click="dismissModal">
        <div class="modalContainer">
          <div
              class="control clickable modalClose"
              @click="$emit('close');"
          >
            <span class="material-icons">close</span>
          </div>
          <ModalMessage
              v-if="modalType == 'message'"
              @close="$emit('close')"
              :headline="headline"
              :message="message"
          />
          <ModalProductAmmount
              v-if="modalType == 'productAmmount'"
              @close="$emit('close')"
              :headline="headline"
              :product="product"
          />
          <ModalWarning
              v-if="modalType == 'warning'"
              @close="$emit('close')"
              @accepted="$emit('accepted')"
              :headline="headline"
              :message="message"
          />
          <OrderDateSelection
              v-if="modalType == 'dateSelection'"
              @close="$emit('close')"
              @dateSelected="$emit('dateSelected', $event)"
              :headline="headline"
              :message="message"
              :subsidiary="subsidiary"
              :subsidiaryIndex="subsidiaryIndex"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalMessage from "./ModalMessage";
import ModalProductAmmount from "./ModalProductAmmount";
import ModalWarning from "./ModalWarning";
import OrderDateSelection from "./OrderDateSelection";

export default {
  name: "ModalTemplate",
  components: {
    ModalProductAmmount,
    ModalMessage,
    ModalWarning,
    OrderDateSelection
  },
  data(){
    return {

    }
  },
  props: {
    modalType: {
      type: String
    },
    headline: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    product: {
      type: Object,
      default: {}
    },
    subsidiary: {
      type: Object,
      default: {}
    },
    subsidiaryIndex: {
      type: String,
      default: ''
    },
    showModal: {
      type: Boolean
    }
  },
  created(){
  },
  methods: {
    dismissModal (event) {
      if(event.target.classList.contains('modalWrapper')){
        this.$emit("close");
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .modalMask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
  }

  .modalWrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modalContainer {
    min-width: 300px;
    width: 50%;
    margin: 0 auto;
    padding: 20px 20px;
    background-color: $bgColor;
    border-radius: 10px;
    box-shadow: 0 2px 8px $shadowColor;
    position: relative;
    text-align: center;
  }

  .modalClose {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity 0.5s ease;
  }

  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
  }
</style>