<template>
  <div class="modalHeader">
    <h3 class="bolded">
      {{ headline }}
    </h3>
  </div>

  <div class="modalBody">
    <div>
      {{ product.name }}
    </div>
    <div
        class="control clickable"
        @click="resetAmount(product)"
    >
      <span class="material-icons">delete</span>
    </div>
    <input type="number" v-model="basket[product.id]" @change="verifyAmount(product)"/>
    <div>
      {{ getProductSum(product.id, true) }}
    </div>
  </div>
</template>

<script>
import {Basket} from '../storage';
import {cloneObject, PriceFormatter} from '../util';
import emitter from "../Emitter";

export default {
  name: "ModalProductAmmount",
  data(){
    return {
      basket: Basket.get('basket'),
    }
  },
  props: {
    headline: {
      type: String
    },
    product: {
      type: Object
    }
  },
  created(){
  },
  methods: {
    getProductSum(productId, formatted=false){
      const price =  this.basket[productId] * this.product.price;
      if(formatted){
        return PriceFormatter.format(price);
      }

      return price;
    },
    resetAmount(product) {
      this.basket = Basket.get('basket', {});
      delete this.basket[product.id];
      this.$emit('close');

      Basket.set('basket', cloneObject(this.basket));
      emitter.emit('basketChanged');
    },
    verifyAmount (product) {
      this.basket = Basket.get('basket', {});
      if (this.basket[product.id] <= 0) {
        this.resetAmount(product);
      }
      if(product.stock < this.basket[product.id]){
        this.basket[product.id] = product.stock;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .modalHeader {
    h3 {
      margin-top: 0;
      color: $mainColor;
    }
  }

  .modalBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;

    input {
      max-width: 20%;
    }
  }
</style>