import {createApp} from "vue";
import {Config} from './storage';
import Main from './components/Main';


const Holma = {
	vue: null,
	/**
	 * @param {Object} config
	 */
	init(config){
		for(let i in config){
			Config.set(i, config[i]);
		}
		if(!Config.has('target')){
			throw new Error("missing target in Config");
		}
		
		if(!Config.has('apiUrl')){
			throw new Error('missing apiUrl in Config');
		}
		
		if(!Config.has('apiToken')){
			throw new Error('missing apiToken in Config');
		}
		
		const app = createApp({
			components: {
				Main: Main,
			},
			template: `
				<section class="Holma">
					<Main/>
				</section>
			`,
		});
		app.mount(Config.get('target'));
		
		this.vue = app;
	},
};


export {Holma};