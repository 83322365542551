<template>
  <div>
    Hier kommt Logik ins Spiel
    <button @click="backToStart">Zurück zur Übersicht</button>
  </div>
</template>

<script>
import emitter from "../Emitter";

export default {
  name: "AddToHomescreen",
  methods: {
    backToStart () {
      if (window.location.hash != '') {
        window.location.hash = '';
      }
      emitter.emit('navigationChange', {
        component: 'SubsidiarySelector',
        properties: {},
      });
    }
  }
}
</script>

<style scoped>

</style>