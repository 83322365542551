function cloneObject(obj){
	return JSON.parse(JSON.stringify(obj));
}


function getRandomId(){
	return Math.random().toString(32).slice(2);
}

const PriceFormatter = new Intl.NumberFormat('de-DE', {
	style: 'currency',
	currency: 'EUR',
});

const FloatFormatter = new Intl.NumberFormat('en-US', {
	style: 'decimal',
	useGrouping: false,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export {
	cloneObject,
	getRandomId,
	PriceFormatter,
	FloatFormatter
};