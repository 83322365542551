<template>
  <div class="modalHeader">
    <h3 class="bolded">
      {{ headline }}
    </h3>
  </div>

  <div class="modalBody">
    <p v-html="message"></p>
  </div>
</template>

<script>
export default {
  name: "ModalMessage",
  data(){
    return {

    }
  },
  props: {
    headline: {
      type: String
    },
    message: {
      type: String
    }
  },
  created(){
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
  .modalHeader {
    h3 {
      margin-top: 0;
      color: $mainColor;
    }
  }

  .modalBody {
    margin: 20px 0;
  }
</style>