<template>
  <ModalTemplate
      @close="showModal = false"
      @accepted="onSubsidiarySelect(lastIndex, true)"
      @dateSelected="onDateSelect($event)"
      :showModal="showModal"
      :modalType="modalType"
      :headline="modalHeadline"
      :message="modalMessage"
      :subsidiary="selectedSubsidiary"
      :subsidiaryIndex="selectedSubsidiaryIndex"
  />
	<div>
    <div class="welcomeWrapper">
      <h2 class="bolded" v-html="welcomeHeadline"></h2>
      <p v-html="welcomeMessage"></p>
    </div>
    <div class="subsidiary-headline">
      <h2 class="bolded">Wählen Sie Ihre Filliale!</h2>
    </div>
		<div class="subsidiary-list">
			<div v-if="loaded" v-for="(subsidiary, index) in subsidiaries" :key="subsidiary.id">
				<SubsidiaryCard :subsidiary="subsidiary" @click="onSubsidiarySelect(index)"/>
			</div>
			<div class="spinner-border" v-else>
				<PulseLoader :loading="!loaded" color="#FDA87B" />
			</div>
		</div>
	</div>
</template>

<script>
import SubsidiaryCard from './SubsidiaryCard';
import {Navigation} from '../storage';
import emitter from '../Emitter';
import restClient from '../rest';
import {Basket, Config} from '../storage';
import PulseLoader from 'vue-spinner/src/PulseLoader';
import ModalTemplate from "./ModalTemplate";

export default {
	name: "SubsidiarySelector",
	components: {
    ModalTemplate,
		SubsidiaryCard,
		PulseLoader,
	},
	data(){
		return {
			subsidiaries: [],
			lastIndex: null,
			loaded: false,
      showModal: false,
      modalType: '',
      modalHeadline: '',
      modalMessage: '',
      welcomeHeadline: '',
      welcomeMessage: '',
      selectedSubsidiary: {},
      selectedSubsidiaryIndex: ''
		};
	},
	async created(){
    this.welcomeHeadline = Config.get('welcomeHeadline');
    this.welcomeMessage = Config.get('welcomeMessage');

		//todo: daten laden
		const result = await restClient.ajax({
			endpoint: 'Holma/subsidiaryList',
			method: 'GET',
		});

		
		if(result.success){
			this.subsidiaries = result.data.subsidiaries;
			this.loaded = true;
		}else{
			//fehler, keine subsidiaries
		}
	},
	methods:{
    renderModal (params) {
      this.modalType = params.type;
      this.modalHeadline = params.headline;
      this.modalMessage = params.message;
      this.selectedSubsidiary = params.selectedSubsidiary;
      this.selectedSubsidiaryIndex = params.selectedSubsidiaryIndex;

      this.showModal = true;
    },
		onSubsidiarySelect(index, force=false){
			const lastSubsidiary = Basket.get('lastSubsidiary');
			
			if(!force && lastSubsidiary && this.subsidiaries[index].id !== lastSubsidiary.id){
				const basketHasContent = Object.keys(Basket.get('basket', {})).length;
				if(basketHasContent){
          this.renderModal({
            type: 'warning',
            headline: 'Achtung',
            message: Config.get('changeSubsidiaryMessage')
          });

					this.lastIndex = index;
					return;
				}
			}

      this.renderModal({
        type: 'dateSelection',
        headline: 'Abholen',
        message: Config.get('selectingDateMessage'),
        selectedSubsidiary: this.subsidiaries[index],
        selectedSubsidiaryIndex: index
      });
		},
    onDateSelect (index) {
      emitter.emit('navigationChange', {
        component: 'ProductListing',
        componentDisplayName: 'Filialübersicht',
        properties:{
          subsidiary: this.subsidiaries[index],
        }
      });
    },
	}
}
</script>

<style scoped lang="scss">
.spinner-border{
		margin-top: 10vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		min-height: 50vh;
	}

  .welcomeWrapper {
    text-align: center;
    margin: 24px 12px 24px 12px;
    h2 {
      display: block;
      color: $mainColor;
      font-size: 36px;
      margin: 24px 0;
    }
  }

  .subsidiary-headline {
    h2 {
      display: block;
      color: $mainColor;
      font-size: 36px;
      margin: 24px 0;
      text-align: center;
    }
  }
	.subsidiary-list{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		justify-content: space-evenly;
		
		>div{
			width: calc(100% - 10px);
      margin: 0 5px;
      @include md {
        width: calc(50% - 10px);
      }
      @include xl {
        width: calc(33% - 10px);
      }
		}
	}
</style>