import NonPersistentStorage from './NonPersistentStorage';

/* global window */
class PersistentStorage extends NonPersistentStorage{
	/**
	 * @param {String} storageKey
	 * @param storage
	 */
	constructor(storageKey, storage = window.localStorage){
		super();
		this.storageKey = storageKey;
		this.storage = storage;
		this.regeneratedStore = false;
		if(this.storage[storageKey]){
			this.store = JSON.parse(this.storage[storageKey]);
			this.regeneratedStore = true;
		}
	}
	
	/**
	 * @inheritDoc
	 */
	set(key, value){
		this.store[key] = value;
		return this.sync();
	}
	
	/**
	 * @inheritDoc
	 */
	clear(){
		this.store = {};
		return this.sync();
	}
	
	/**
	 * @inheritDoc
	 */
	delete(key){
		if(this.has(key)){
			delete this.store[key];
		}
		return this.sync();
	}
	
	/**
	 * @return {PersistentStorage}
	 */
	sync(){
		this.storage[this.storageKey] = JSON.stringify(this.store);
		return this;
	}
	
	/**
	 * @return {boolean}
	 */
	storeWasRegenerated(){
		return this.regeneratedStore;
	}
}

export default PersistentStorage;