  <template>
	<div class="product-border" :class="{hasImage: image}">
		<div class="product-image" v-if="image">
			<img :src="image" :alt="product.name" />
		</div>
		<div class="product-details">
			<div class="product-name">
				<h3>
					{{ product.name }}
					<span
						class="material-icons clickable"
						v-if="product.additive"
						role="button"
						aria-label="Inhaltsstoffe"
            @click="showInfo = !showInfo"
					>
						info
					</span>
				</h3>
        <div class="basket-controls">
          <div
              class="control clickable"
              v-if="basket[product.id]"
              @click="resetAmount(product)"
          >
            <span class="material-icons">delete</span>
          </div>
          <div class="control clickable" :class="{active: basket[product.id]}" v-if="basket[product.id]" @click="$emit('showModal', {
            type: 'productAmmount',
            headline: 'Menge ändern',
            product: product
          });">
            {{ basket[product.id] }}
          </div>
        </div>
			</div>
      <div class="product-content">
        <transition name="fade">
          <div v-if="showInfo" v-html="product.additive" class="product-additive"></div>
        </transition>
        <div v-html="product.description" class="product-description">
        </div>
      </div>
			<div class="product-price">
				<p class="bolded">{{formattedPrice}}</p>
        <div class="basket-controls">
          <div
              class="control clickable"
              v-if="basket[product.id]"
              @click="decrement(product)"
              role="button"
              aria-label="Zum Warenkorb hinzufügen"
          >
            <span class="material-icons">remove</span>
          </div>
          <div
              class="control clickable"
              :class="{active: basket[product.id]}"
              @click="increment(product);"
              role="button"
              aria-label="Aus dem Warenkorb nehmen"
          >
            <span class="material-icons">add</span>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import {Basket, Config} from '../storage';
import {cloneObject} from '../util';
import emitter from '../Emitter';
import {PriceFormatter} from '../util';

export default {
	name: "Product",
	data(){
		return {
			basket: {},
      showInfo: false,
      showModal: false
		};
	},
	props: {
		product: {
			type: Object,
		}
	},
	computed: {
		image(){
			if(this.product.media){
				return `${Config.get('apiUrl')}/${this.product.media}`;
			}
			
			return false;
		},
		formattedPrice(){
			return PriceFormatter.format(this.product.price);
		}
	},
	created(){
		this.basket = Basket.get('basket', {});
	},
	methods: {
		increment(product){
			this.basket = Basket.get('basket', {});
			if(!this.basket[product.id]){
				if(product.stock > 0){
					this.basket[product.id] = 1;
				}
			}else{
				if(product.stock > this.basket[product.id]){
					this.basket[product.id] += 1;
				} else {
          this.$emit('showModal',{
            type: 'message',
            headline: 'Hallo,',
            message: Config.get('maxStockMessage')
          });
        }
			}
			
			Basket.set('basket', cloneObject(this.basket));
			emitter.emit('basketChanged');
		},
		decrement(product){
			this.basket = Basket.get('basket', {});
			if(this.basket[product.id] === 1){
				delete this.basket[product.id]
			}else{
				this.basket[product.id] -= 1;
			}
			
			Basket.set('basket', cloneObject(this.basket));
			emitter.emit('basketChanged');
		},
    resetAmount(product) {
      this.basket = Basket.get('basket', {});
      delete this.basket[product.id]

      Basket.set('basket', cloneObject(this.basket));
      emitter.emit('basketChanged');
    }
	},
}
</script>

<style scoped lang="scss">
	.product-border{
    display: grid;
    grid-template-columns: 100%;
		grid-template-rows: 100%;
		gap: 0 0;
		margin: 8px;
		align-items: stretch;
		flex-grow: 1;
		border: 1px solid $borderColor;
    position: relative;
    @include md {
      margin: 12px;
    }
    @include lg {
      margin: 16px;
    }

    &.hasImage {
      grid-template-columns: 33% 67%;
    }
		
		>div {
			align-self: center;
		}

    .product-details {
      padding: 8px 12px;
      .control {
        border: 1px solid $mainColor;
        color: $mainColor;
        width: 40px;
        height: 40px;
        align-self: end;
        user-select: none;

        display:flex;
        justify-content: center;
        align-items: center;

        &.active{
          border: none;
          color: $lightFont;
          background-color: $mainColor;

        }
      }

      .product-name {
        display: grid;
        grid-template-columns: 70% 30%;
        min-height: 40px;
        align-items: center;
        h3 {
          font-weight: bold;
          font-size: 18px;
          @include md {
            font-size: 28px;
          }
        }
        .basket-controls {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .product-content {
        position: relative;
        .product-additive {
          position: absolute;
          width: 100%;
          background: $bgDark;
          color: $lightFont;
          border-radius: 10px;
          padding: 5px;
          margin: 5px 0;
          z-index: 1;
          > p {
            display: block;
          }
        }

        .fade-enter-active, .fade-leave-active {
          transition: all 0.5s;
        }
        .fade-enter-from, .fade-leave-to /* .list-leave-active below version 2.1.8 */ {
          opacity: 0;
          transform: translateY(calc(-100%));
        }
      }
    }

    .product-image{
			width: 100%;
      height: auto;
      align-self: flex-start;
			
			img{
				width: 95%;
				height: 100%;
        vertical-align: middle;
        object-fit: contain;
			}
			margin-right: 8px;
		}

		.product-price{
      display: grid;
      grid-template-columns: 50% 50%;
			color: $mainColor;

      .plus-minus{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: auto;
        >div:last-child{
          margin-left: 4px;
        }
      }

      .basket-controls {
        position: absolute;
        bottom: 0;
        right: 0;
      }
		}
		
		.basket-controls{
			display: flex;
			flex-direction: row;
			align-self: stretch;
      justify-content: flex-end;
      //margin-top: auto;
      >div:last-child{
        margin-left: 4px;
      }
			@include md {
        min-width: 100px;
      }
		}
	}
</style>