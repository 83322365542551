<template>
  <ModalTemplate
      @close="showModal = false"
      :showModal="showModal"
      :modalType="modalType"
      :headline="modalHeadline"
      :message="modalMessage"
      :subsidiary="subsidiary"
  />
	<div class="basket">
		<BackButton component="ProductListing" componentName="Produktauswahl" />
		<div class="basket-container">
			<div class="product-list">
				<div>
					<h2 class="bolded">Warenkorb</h2>
				</div>
				<div class="product" v-for="(amount, productId) in basket" :key="productId">
					<div>
						{{ products[productId].name }}
					</div>
          <div
              class="control clickable"
              @click="resetAmount(products[productId])"
          >
            <span class="material-icons">delete</span>
          </div>
					<div>
						<input type="number" v-model="basket[productId]" @change="verifyAmount(products[productId])"/>
					</div>
					<div>
						{{ getProductSum(productId, true) }}
					</div>
				</div>
				<hr />
				<div class="basket-summary">
					<div>
						<div>
							Zwischensumme
						</div>
						<div>
							{{ formattedSum }}
						</div>
					</div>
					<div>
						<div>
							Mwst.
						</div>
						<div>
							{{ formattedVat }}
						</div>
					</div>
					<div>
						<div class="bolded">
							Gesamt
						</div>
						<div class="bolded">
							{{ formattedTotal }}
						</div>
					</div>
				</div>
			</div>
			<div class="order-details">
        <h3>Informationen zur Abholung</h3>
        <div class="order-location">
          <label>
            Abholort
          </label>
          <div class="location-wrapper">
            <input type="text" placeholder="Abholort" v-model="orderCity" disabled />
            <span class="clickable material-icons" @click="showLocationModal">
              place
            </span>
          </div>
        </div>
				<div class="order-time">
					<div>
						<label>
							Abholdatum
            </label>
            <input type="date" placeholder="Abholdatum" v-model="orderDate" :class="orderDate == '' ? 'required' : ''" disabled />
					</div>
					<div>
						<label>
              Abholzeit
            </label>
						<input @change="updatedTime" type="time" placeholder="Abholdatum" v-model="orderTime" :min="minTime" :max="maxTime" :class="orderTime == '' ? 'required' : ''" />
					</div>
				</div>
        <div>
          <label>
            Bestellt auf
          </label>
          <input type="text" placeholder="Abholname" v-model="orderName" :class="orderName == '' ? 'required' : ''" />
        </div>
        <div>
          <label>
            Memo
          </label>
          <textarea placeholder="Nachrichtentext" v-model="orderMemo" cols="32" rows="5"></textarea>
        </div>
        <transition name="fade">
          <div v-if="timeError" class="timeErrorMessage">{{ timeErrorMessage }}</div>
        </transition>
			</div>
		</div>
    <div>
      <paypalButtons
        v-if="products"
        :products="products"
        :totalSum="parseFloat(totalSum)"
        :totalVat="parseFloat(totalVat)"
        :orderTime="orderTime"
        :orderDate="orderDate"
        :orderName="orderName"
        :orderMemo="orderMemo"
        :isActive="orderName !== '' && orderTime !== '' && orderDate !== ''"
      />
    </div>
    <div class="condition-confirmation">
      <p>
        Mit dem Absenden der Bestellung akzeptieren Sie unsere AGB und erklären, dass Sie über Ihr Widerrufsrecht informiert wurden.
      </p>
    </div>
    <div class="continueShoppingButton">
      <button class="clickable" role="button" @click="continueShopping">
        <span class="material-icons icon">west</span>
        <span class="label">Weiter einkaufen</span>
      </button>
    </div>
	</div>
</template>

<script>
import BackButton from './BackButton';
import restClient from '../rest';
import {cloneObject} from '../util';
import {Basket, Navigation} from '../storage';
import paypalButtons from './paypalButtons';
import {PriceFormatter} from '../util';
import emitter from "../Emitter";
import ModalTemplate from "./ModalTemplate";

export default {
	name: "Basket",
	components: {
		BackButton,
    paypalButtons,
    ModalTemplate
	},
	data(){
		return {
			products: null,
			basket: {},
      orderCity: '',
			orderName: '',
			orderDate: '',
			orderTime: '',
      orderMemo: '',
      subsidiary: '',
      minTime: '',
      maxTime: '',
      timeError: false,
      timeErrorMessage: '',
      showModal: false,
      modalType: '',
      modalHeadline: '',
      modalMessage: ''
		};
	},
	props: {
		allProducts: {
			type: Array,
			default: () => [],
		}
	},
	async created(){
		let products = cloneObject(this.allProducts)
		if(products.length === 0){
			const result = await restClient.ajax({
				endpoint: 'Holma/articleList',
				method: 'GET',
			});
			
			if(result.success){
				products = result.data;
			}else{
				//fehler keine daten
			}
		}

    this.products = {};
		products.forEach(prod => {
			this.products[prod.id] = prod;
		});
		
		this.basket = Basket.get('basket');
    this.orderDate = Basket.get('orderDate');

    this.subsidiary = Basket.get('lastSubsidiary');
    let date = new Date(this.orderDate);
    this.subsidiary.opening_hours.forEach(function (item, index) {
      if (item.weekday == date.getDay()) {
        if (item.open.length == 4) {
          item.open = '0' + item.open;
        }
        this.minTime = item.open;
        this.maxTime = item.close;
      }
    }, this);

    this.orderCity = this.subsidiary.location.city;
	},
	computed:{
		totalSum(){
			let sum = 0;
			for(let id in this.basket){
				sum += parseFloat(this.getProductSum(id)) * (1 -this.products[id].vat);
			}
			
			return sum.toFixed(3);
		},
		totalVat(){
			let sum = 0;
			for(let id in this.basket){
				sum+= (this.basket[id] * this.products[id].price) * this.products[id].vat;
			}
			
			return sum.toFixed(3);
		},
		formattedSum(){
			return PriceFormatter.format(parseFloat(this.totalSum));
		},
		formattedVat(){
			return PriceFormatter.format(parseFloat(this.totalVat));
		},
		formattedTotal(){
			return PriceFormatter.format(parseFloat(this.totalSum) + parseFloat(this.totalVat));
		},
	},
	methods:{
		getProductSum(productId, formatted=false){
			const price =  this.basket[productId] * this.products[productId].price;
			if(formatted){
				return PriceFormatter.format(price);
			}
			
			return price;
		},
    resetAmount(product) {
      this.basket = Basket.get('basket', {});
      delete this.basket[product.id]

      Basket.set('basket', cloneObject(this.basket));
      emitter.emit('basketChanged');
    },
    verifyAmount (product) {
      this.basket = Basket.get('basket', {});
      if (this.basket[product.id] <= 0) {
        this.resetAmount(product);
      }
      if(product.stock < this.basket[product.id]){
        this.basket[product.id] = product.stock;
      }

      Basket.set('basket', cloneObject(this.basket));
      emitter.emit('basketChanged');
    },
    updatedTime (event) {
      if (!event.target.validity.valid) {
        this.timeErrorMessage = event.target.validationMessage;
        this.timeError = true;
      } else {
        this.timeErrorMessage = '';
        this.timeError = false;
      }
    },
    continueShopping () {
      emitter.emit('navigationChange', {
        component: 'ProductListing',
        componentDisplayName: 'Produktauswahl',
        properties: Navigation.get('lastComponentParams', {}),
      });
    },
    showLocationModal () {
      this.modalType = 'message';
      this.modalHeadline = "Unser Standort";
      this.modalMessage = this.subsidiary.name + "<br />" +
                          this.subsidiary.location.street + " " + this.subsidiary.location.street_nr + "<br />" +
                          this.subsidiary.location.zip + " " + this.subsidiary.location.city + "<br />" +
                          this.subsidiary.phone + "<br />" +
                          this.subsidiary.email;

      this.showModal = true;
    }
	}
}
</script>

<style scoped lang="scss">
	.basket{
		width: 100%;
		
		.basket-container{
      width: calc(100% - 20px);
      margin: 0 10px 0 10px;
      @include md {
        width: calc(100% - 40px);
        margin: 0 20px 0 20px;
      }
      @include xl {
        width: calc(100% - 60px);
        margin: 0 30px 0 30px;
      }
			
			.product-list{
				display: flex;
				flex-direction: column;
				width: 100%;
				
				.product{
          align-items: center;
          display: grid;
          grid-template-columns: 60% 5% 15% 20%;
          margin: 4px 0;
					width: 100%;
					
					div{
						input{
							width: 100%;
              text-align: center;
						}
						
						&:last-child{
							text-align: right;
						}
					}

          .control {
            text-align: center;
          }
				}
				
			}
		}
		
		.basket-summary{
			>div{
				display:flex;
				flex-direction: row;
				justify-content: space-between;
			}
		}

    .order-details {
      margin-top: 24px;
      .order-location {
        .location-wrapper {
          display: flex;
          align-items: center;
          .material-icons {
            margin-left: 8px;
          }
        }
      }
      .order-time{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        input {
          display: block;
        }
      }
      input[type='time'] {
        &:-webkit-datetime-edit-text {
          content: ':';
        }
        &::-webkit-datetime-edit-hour-field,
        &::-webkit-datetime-edit-minute-field {
          content: '--';
        }
      }

      label {
        display: block;
        margin-top: 16px;
      }

      .timeErrorMessage {
        border: solid red 1px;
        border-radius: 5px;
        background: rgba(255, 0, 0, 0.5);
        margin: 5px 0;
        padding: 5px;
      }

      .fade-enter-active, .fade-leave-active {
        transition: all 0.5s;
      }
      .fade-enter-from, .fade-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
      }

      .required {
        border: 1px solid red;
        margin: -1px;
      }
    }

    input,
    textarea {
      border-radius: 5px;
      padding: 4px 10px;
    }

    .continueShoppingButton {
      position: sticky;
      bottom: 0px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      background: $bgColor;
      z-index: 100;
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 700px;
        padding: 8px;
        color: $lightFont;
        .icon {
          margin-right: 30px;
        }
      }
    }
	}
</style>